var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscriptions mt-4"},[_c('v-row',[(_vm.showMenuNavigator)?_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"0","md":"3"}},[_c('MenuNavigator',{staticClass:"menu-navigator"})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":_vm.showMenuNavigator ? '9' : '12'}},[(!_vm.showMenuNavigator)?_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"position1"}}):_vm._e(),_c('v-container',{staticClass:"pt-0 subscriptions"},[(_vm.category)?_c('div',{staticClass:"category-title"},[(!_vm.category.metaData.category_info.HIDE_TITLE)?_c('h2',{staticClass:"primary--text text--lighten-1 pa-3 pt-0 pl-0 pt-sm-3"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(!_vm.category.metaData.category_info.HIDE_TITLE && _vm.subtitle)?_c('div',{staticClass:"default--text pb-3",domProps:{"innerHTML":_vm._s(_vm.subtitle)}}):_vm._e()]):_vm._e(),_c('v-row',{staticClass:"pa-4",attrs:{"no-gutters":""}},_vm._l((Object.keys(_vm.$t('subscriptions')).length),function(step,index){return _c('v-col',{key:index,staticClass:"d-flex flex-column text-center justify-center align-center h-100 px-2 pb-0 pt-4",class:{
                'step-separator':
                  index < Object.keys(_vm.$t('subscriptions')).length - 1
              },attrs:{"cols":"12","sm":"3","md":"3"}},[_c('div',{staticClass:"secondary default--text dot d-flex justify-center align-center"},[_c('span',{staticClass:"number",domProps:{"innerHTML":_vm._s(_vm.$t('subscriptions.' + Number(index + 1) + '.number'))}})]),_c('div',[_c('strong',{domProps:{"innerHTML":_vm._s(_vm.$t('subscriptions.' + Number(index + 1) + '.title'))}})]),_c('div',[_c('p',{staticClass:"stepper-description",domProps:{"innerHTML":_vm._s(
                    _vm.$t('subscriptions.' + Number(index + 1) + '.description')
                  )}})])])}),1),_c('h4',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("subscriptionsModule.avaibilityDisclaimer"))+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"cart-info pa-0",attrs:{"cols":"12","md":"12","order":"1","order-md":"0"}},[_c('v-list',{staticClass:"cart-item-list checkout-item-list",attrs:{"id":"subscriptionList","flat":"","subheader":"","dense":""}},[_c('v-list-item-group',{key:_vm.renderKey,staticClass:"flex align-center"},_vm._l((_vm.subscriptions),function(item){return _c('v-list-item',{key:item.subscriptionPlanId,staticClass:"px-0"},[_c('div',{staticClass:"cart-item subscription pa-3"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex flex-md-row flex-sm-row flex-column align-center",attrs:{"cols":"12","sm":"2","md":"2"}},[(item.product && item.product.mediaURL)?_c('img',{attrs:{"src":item.product.mediaURL,"alt":'Immagine prodotto'}}):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-md-row flex-sm-row flex-column align-center pl-1",attrs:{"cols":"12","sm":!_vm.subscriptionActive(item) ? '4' : '7',"md":!_vm.subscriptionActive(item) ? '4' : '7',"lg":!_vm.subscriptionActive(item) ? '5' : '7'}},[_c('div',{staticClass:"description px-2 pt-3 px-sm-0 px-md-0 pt-sm-0 pt-md-0"},[_c('div',{staticClass:"description text-wrap font-weight-bold"},[_vm._v(" "+_vm._s(item.descr)+" ")])])]),(
                            _vm.$vuetify.breakpoint.smAndUp &&
                              !_vm.subscriptionActive(item) &&
                              item.product
                          )?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"4","sm":"2","md":"2"}},[_c('ProductPrice',{attrs:{"product":item.product,"showDescrWeight":false,"showOldPrice":false}})],1):_vm._e(),(_vm.subscriptionActive(item) && !_vm.isExpiring(item))?_c('v-col',{staticClass:"d-flex flex-column",class:{
                            'align-start':
                              _vm.subscriptionActive(item) &&
                              _vm.$vuetify.breakpoint.xsOnly
                          },attrs:{"cols":"8","sm":"3","md":"3"}},[_c('div',{staticClass:"d-flex justify-center subscription-status"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("subscriptionsModule.activeSusbscription"))+" ")])]),_c('div',{staticClass:"d-flex justify-center subscription-expire"},[_c('b',[_vm._v("Scadenza "+_vm._s(_vm.$dayjs(item.renewalDate).format("D MMMM YYYY")))])]),_c('v-btn',{staticClass:"primary lighten-1 subs-btn",attrs:{"medium":"","depressed":"","disabled":_vm.subscriptionAdded(item.subscriptionPlanId) ||
                                _vm.subscriptionAdded(item.subscriptionPlanId) ||
                                (_vm.subscriptionActive(item) && !_vm.isExpiring(item))},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addSubscriptionPlan(item.subscriptionPlanId)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("subscriptionsModule.renewSubscription"))+" ")])])],1):(
                            _vm.subscriptionActive(item) && _vm.isExpiring(item)
                          )?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"8","sm":"2","md":"2"}},[_c('div',{staticClass:"d-flex justify-center subscription-status expiring"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("subscriptionsModule.expiringSubscription")))])])]),_c('div',{staticClass:"d-flex justify-center subscription-expire"},[_c('b',[_vm._v(_vm._s(_vm.$t("subscriptionsModule.expiring"))+" "+_vm._s(_vm.$dayjs(item.renewalDate).format("D MMMM YYYY")))])]),_c('v-btn',{staticClass:"primary lighten-1 subs-btn expired-btn",attrs:{"medium":"","depressed":"","disabled":_vm.subscriptionAdded(item.subscriptionPlanId) ||
                                !_vm.cart.homeDelivery},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addSubscriptionPlan(item.subscriptionPlanId)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("subscriptionsModule.renewSubscription"))+" ")])])],1):_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"3","md":"3"}},[(_vm.subscriptionOrdered(item))?_c('div',{staticClass:"d-flex justify-center subscription-status"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("subscriptionsModule.orderedSubscription"))+" ")])]):_vm._e(),_c('v-btn',{staticClass:"primary lighten-1 subs-btn",attrs:{"medium":"","depressed":"","disabled":_vm.subscriptionAdded(item.subscriptionPlanId) ||
                                !_vm.subscriptionPaid(item) ||
                                !_vm.cart.homeDelivery},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addSubscriptionPlan(item.subscriptionPlanId)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("$cart")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("subscriptionsModule.addToCart"))+" ")])],1)],1)],1)],1)])}),1)],1)],1)],1)],1),_c('v-spacer',{staticClass:"spacer-scroll"}),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }